<template>
    <div>
        <el-upload :action="uploadUrl" list-type="text" ref="upload" :show-file-list="false" :auto-upload="true"
            accept=".jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.txt" :on-success="onSuccess" :limit="10"
            :on-exceed="uploadExceed" :before-upload="uploadBefore" class="upload-demo" >       
            <el-button size="small" type="primary" >上传</el-button>
            <span  class="el-upload__tip" slot="tip">注：支持.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.txt文件，且不超过10MB</span >
        </el-upload>
    </div>
</template>
<script>
export default {
    data() {
        return {
            uploadUrl: window.AppConfig.apiCustomerUrl + "omsapi/files/upload?currentType=" + this.$store.state.global.currentType + "&currentCCode=" + this.$store.state.global.currentCCode + "&token=" + this.Utils.getToken(),
            postData: {
                FileType: 500,
                Type: 1, //类型码:1导入2导出
                BusinessType: 5 //运单为5
            },
            currentFile:{},
            fileList:[]
        };
    },
    props: {
        fileType: 0,
        disabled: false
    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        onSuccess(response, file, fileList) {
            if(response.IsSuccess){
				if (response.Result) {
					this.currentFile = response.Result;
                    this.$emit("currentFile", this.currentFile);
				} else {
					this.$message.error(response.OperationDesc);
				}
			}
        },
        uploadBefore(file) {
			const picType = (file.type === 'image/jpeg') || (file.type === 'image/png') ||
				(file.type === 'application/msword') || (file.type === 'application/vnd.ms-excel') ||
				(file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
				(file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
				(file.type === 'text/plain') || (file.name.endsWith('.xls')) || (file.name.endsWith('.xlsx'));
			const isLt50M = file.size / 1024 / 1024 < 10;
			console.log("校验类型");
			if (!picType) {
				this.$message.error('上传文件只能是.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.txt!');
			}
			console.log("校验大小");
			if (!isLt50M) {
				this.$message.error('上传文件大小不能超过 10MB!');

			}
			return picType && isLt50M;
        },
        uploadExceed(files, fileList) {
            this.Utils.messageBox(`当前限制选择20个文件，本次选择了${files.length}个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
    }
}
</script>

<style>
.el-upload__input {
    display: none !important;
}

.el-upload-dragger {
    width: 512px !important;
}

.el-upload__tip {
    color: red !important;
}

.el-upload__status_tip {
    margin-top: 10px;
    max-height: 300px;
    overflow: auto;
}

.el-upload {
    width: 125px !important;
}
</style>
